import { PROJECTS } from "../Constants/constants"
import { motion } from "framer-motion"

export default function Projects() {
    return (
        <section id="projects">
            <div className="border-neutral-900 py-20 pb-4 border-b">
                <h1 className="my-20 text-4xl text-center">Projects</h1>
                <div>{PROJECTS.map((project, index) => (
                    <motion.div
                        key={index}
                        whileInView={{ opacity: 1, x: 0 }}
                        initial={{ opacity: 0, x: -50 }}
                        transition={{ duration: 0.5 }}>
                        <div className="lg:justify-center flex flex-wrap mb-8">
                            <div className="lg:w-1/4 w-full">
                                <img
                                    src={project.image}
                                    alt={project.title}
                                    width={150}
                                    height={150}
                                    className="mb-6 rounded"
                                />
                            </div>
                            <div className="lg:w-3/4 w-full max-w-xl">
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="no-underline"
                                >
                                    <h6 className="mb-2 font-semibold underline">
                                        {project.title}
                                    </h6>
                                    <p className="text-neutral-400 mb-4" style={{ whiteSpace: 'pre-line' }}>
                                        {project.description}
                                    </p>
                                </a>
                                {project.technologies.map((tech, index) => (
                                    <span key={index} className="bg-neutral-900 px-2 py-1 mr-2 text-sm font-medium text-purple-500 rounded">{tech}</span>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                ))}
                </div>
            </div>
        </section >
    )
}